<template>
    <div class="language-selector">
        <div class="input-container">
            <label for="change-language">{{ $t("footer.change_language") }}</label>
            <div class="select">
                <v-select
                    v-if="!loadingLanguages"
                    id="change-language"
                    class="custom-styles"
                    :value="getValue()"
                    :searchable="false"
                    :clearable="false"
                    :options="languageOptions()"
                    label="translation"
                    @input="changeLanguage">
                </v-select>
                <div v-else>
                    Loading...
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  computed: {
    ...mapGetters(['isAuthenticated', 'loadingLanguages', 'languages']),
  },
  methods: {
    getValue() {
      let language = this.languages.find(language => language.code === this.$i18n.locale);
      return language;
    },
    languageOptions() {
      this.languages.forEach(language => {
        return (language.translation = this.$t(`locale_change.${language.name}`));
      });

      return this.languages;
    },
    async changeLanguage(selectedLanguage) {
      if (this.isAuthenticated) {
        await this.$api.post('profile/language', { language_id: selectedLanguage.id });
      }
      localStorage.setItem('currentLanguage', JSON.stringify(selectedLanguage));

      this.$gtm.trackEvent({
        category: 'Interaction',
        action: 'select',
        label: selectedLanguage.name,
      });

      location.reload();
    },
  },
};
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.custom-styles .vs__selected {
  color: #fff;
}

.language-selector {
  border-bottom: 1px solid $color-palette-grey-6;
  text-align: center;
  padding: 50px 0 0;

  .input-container {
    max-width: 180px;
    margin: 0 auto;
  }

  .select {
    height: 35px;
    margin-top: 10px;

    @media screen and (max-width: 1024px) {
      font-size: 16px;
    }
  }

  .vs__selected:focus {
    margin: 0;
  }
}
</style>
