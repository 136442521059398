import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import api from '@/api';
import { objectToFormData } from '@/lib/formData';

const state = {
  moments: [],
  links: {},
  isLoading: false,
  perPage: 12,
  currentPage: 1,
  action: null,
  actionMoment: {},
  newMoment: {},
};

const getters = {
  moments: state => orderBy(state.moments, [moment => moment.created_at], ['desc']),
  links: state => state.links,
  isLoading: state => state.isLoading,
  perPage: state => state.perPage,
  hasNextPage: state => !!state.links.next,
  currentPage: state => state.currentPage,
  action: state => state.action,
  actionMoment: state => state.actionMoment,
  newMoment: state => state.newMoment,
};

const mutations = {
  SET_MOMENTS(state, moments) {
    state.moments = moments;
  },
  ADD_MOMENTS(state, moments) {
    const momentsUidSet = new Set(moments.map(moment => moment.uid));

    state.moments = [
      ...state.moments.filter(stateMoment => !momentsUidSet.has(stateMoment.uid)),
      ...moments,
    ];
  },
  ADD_MOMENT(state, moment) {
    const stateMoment = state.moments.find(stateMoment => stateMoment.uid === moment.uid);

    if (!stateMoment) {
      state.moments.push(moment);
    }
  },
  UPDATE_MOMENT(state, moment) {
    const stateMoment = state.moments.find(stateMoment => stateMoment.uid === moment.uid);

    if (stateMoment) {
      Object.assign(stateMoment, moment);
    }
  },
  REMOVE_MOMENT(state, moment) {
    state.moments = state.moments.filter(item => item.uid !== moment.uid);
  },
  SET_LINKS(state, links) {
    state.links = links || {};
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading || false;
  },
  SET_CURRENT_PAGE(state, page) {
    state.currentPage = page;
  },
  INCREASE_CURRENT_PAGE(state) {
    state.currentPage++;
  },
  SET_ACTION_MOMENT(state, moment) {
    state.actionMoment = { ...moment };
  },
  SET_ACTION(state, action) {
    state.action = action || null;
  },
  SET_NEW_MOMENT(state, moment) {
    state.newMoment = { ...moment };
  },
};

const actions = {
  doAction({ commit }, { moment, action }) {
    commit('SET_ACTION_MOMENT', moment || {});
    commit('SET_ACTION', action);
  },
  clearAction({ commit }) {
    commit('SET_ACTION', null);
    commit('SET_ACTION_MOMENT', {});
  },
  async loadFirstMomentsBatch({ commit, getters }) {
    if (getters.isLoading) {
      return;
    }

    commit('SET_CURRENT_PAGE', 1);
    commit('SET_IS_LOADING', true);

    const response = await api.get(
      `moments?per_page=${getters.perPage}&page=${getters.currentPage}&sort=created_at|desc`,
      { cache: false },
    );

    commit('SET_MOMENTS', get(response, 'data.data', []));
    commit('SET_LINKS', get(response, 'data.links', {}));
    commit('SET_IS_LOADING', false);
  },
  async loadNextMomentsBatch({ commit, getters }) {
    if (!getters.hasNextPage || getters.isLoading) {
      return;
    }

    commit('INCREASE_CURRENT_PAGE');
    commit('SET_IS_LOADING', true);

    const response = await api.get(
      `moments?per_page=${getters.perPage}&page=${getters.currentPage}&sort=created_at|desc`,
      { cache: false },
    );

    commit('ADD_MOMENTS', get(response, 'data.data', []));
    commit('SET_LINKS', get(response, 'data.links', {}));
    commit('SET_IS_LOADING', false);
  },
  async loadMoment({ commit }, { uid }) {
    const response = await api.get(`moments/${uid}`);

    commit('ADD_MOMENT', get(response, 'data.data', {}));
  },
  async uploadNewMoment({ commit }, { media, onUploadProgress }) {
    const formData = objectToFormData({
      media,
    });

    const response = await api.post(`moments`, formData, { onUploadProgress });

    commit('SET_NEW_MOMENT', get(response, 'data.data', {}));
  },
  async cropNewMoment({ commit, getters }, { canvas }) {
    const blob = await new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg'));
    const newMoment = getters.newMoment.media_url_mime_type.startsWith('video')
      ? { ...getters.newMoment, media_preview: blob }
      : { ...getters.newMoment, media: blob };

    commit('SET_NEW_MOMENT', newMoment);
  },
  async describeNewMoment({ commit, getters }, { text }) {
    const response = await api.patch(`moments/${getters.newMoment.uid}`, {
      action: 'describe',
      text,
    });

    commit('SET_NEW_MOMENT', {
      ...getters.newMoment,
      ...get(response, 'data.data', {}),
    });
  },
  async confirmNewMoment({ commit, getters }, { onUploadProgress }) {
    const formData = objectToFormData({
      _method: 'patch',
      action: 'confirm',
      text: getters.newMoment.text,
      media: getters.newMoment.media,
      media_preview: getters.newMoment.media_preview,
    });

    const response = await api.post(`moments/${getters.newMoment.uid}`, formData, {
      onUploadProgress,
    });

    commit('ADD_MOMENT', get(response, 'data.data', {}));
  },
  async editMoment({ commit }, { moment }) {
    const response = await api.patch(`moments/${moment.uid}`, {
      action: 'describe',
      text: moment.text,
    });

    commit('UPDATE_MOMENT', get(response, 'data.data', {}));
  },
  async deleteMoment({ commit }, { moment }) {
    await api.delete(`moments/${moment.uid}`);

    commit('REMOVE_MOMENT', moment);
  },
  async likeMoment({ commit }, { moment }) {
    const response = await api.post('like', {
      type: 'App\\Moment',
      id: moment.id,
    });

    commit('UPDATE_MOMENT', {
      uid: moment.uid,
      likes_count: get(response, 'data.likes', 0),
      is_liked_by_current_user: get(response, 'data.action') === 'like',
    });
  },
  async reportMoment({ commit }, { moment, reason }) {
    const response = await api.post(`moments/${moment.uid}/reports`, { reason });

    commit('UPDATE_MOMENT', get(response, 'data.data', {}));
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
