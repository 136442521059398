<template>
    <overlay important center>
        <div class="onboarding">
            <transition name="fade" mode="out-in">
                <div v-if="currentStep === 1" key="step-1" class="onboarding-step">
                    <h1 class="onboarding-headline" v-text="$t('onboarding.step_1.headline')" />
                    <div class="onboarding-text">
                        <p v-text="$t('onboarding.step_1.text_1')" />
                        <p v-text="$t('onboarding.step_1.text_2')" />
                    </div>
                    <img src="@/assets/img/onboarding-moments-1.png" srcset="@/assets/img/onboarding-moments-1@2x.png 2x" class="onboarding-border" alt="Moments Introduction" />
                    <div class="onboarding-actions">
                        <button type="button" class="button-default with-arrow colored-black" @click="close" v-text="$t('onboarding.activate_member_moments')" />
                        <button type="button" class="button-default with-arrow colored-purple" @click="nextStep" v-text="$t('onboarding.start_tutorial')" />
                    </div>
                </div>
                <div v-else-if="currentStep === 2" key="step-2" class="onboarding-step">
                    <h1 class="onboarding-headline" v-text="$t('onboarding.step_2.headline')" />
                    <div class="onboarding-illustration">
                        <img src="@/assets/img/onboarding-moments-2.png" srcset="@/assets/img/onboarding-moments-2@2x.png 2x" alt="Homepage & timeline" />
                        <ol class="onboarding-numbers">
                            <li v-text="$t('onboarding.step_2.text_1')" />
                            <li v-text="$t('onboarding.step_2.text_2')" />
                        </ol>
                    </div>
                    <div class="onboarding-nav">
                        <button type="button" class="onboarding-nav-item is-active" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(3)" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(4)" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(5)" />
                    </div>
                    <div class="onboarding-actions">
                        <button type="button" class="button-default with-arrow colored-black" @click="close" v-text="$t('onboarding.activate_member_moments')" />
                        <button type="button" class="button-default with-arrow colored-purple" @click="nextStep" v-text="$t('onboarding.continue')" />
                    </div>
                </div>
                <div v-else-if="currentStep === 3" key="step-3" class="onboarding-step">
                    <h1 class="onboarding-headline" v-text="$t('onboarding.step_3.headline')" />
                    <div class="onboarding-illustration">
                        <img src="@/assets/img/onboarding-moments-3.png" srcset="@/assets/img/onboarding-moments-3@2x.png 2x" alt="Upload and edit moments" />
                        <ol class="onboarding-numbers">
                            <li v-text="$t('onboarding.step_3.text_1')" />
                            <li v-text="$t('onboarding.step_3.text_2')" />
                        </ol>
                    </div>
                    <div class="onboarding-nav">
                        <button type="button" class="onboarding-nav-item" @click="goToStep(2)" />
                        <button type="button" class="onboarding-nav-item is-active" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(4)" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(5)" />
                    </div>
                    <div class="onboarding-actions">
                        <button type="button" class="button-default with-arrow colored-black" @click="close" v-text="$t('onboarding.activate_member_moments')" />
                        <button type="button" class="button-default with-arrow colored-purple" @click="nextStep" v-text="$t('onboarding.continue')" />
                    </div>
                </div>
                <div v-else-if="currentStep === 4" key="step-4" class="onboarding-step">
                    <h1 class="onboarding-headline" v-text="$t('onboarding.step_4.headline')" />
                    <div class="onboarding-illustration">
                        <img src="@/assets/img/onboarding-moments-4.png" srcset="@/assets/img/onboarding-moments-4@2x.png 2x" alt="Upload & crop images" />
                        <ol class="onboarding-numbers">
                            <li v-text="$t('onboarding.step_4.text_1')" />
                            <li v-text="$t('onboarding.step_4.text_2')" />
                        </ol>
                    </div>
                    <div class="onboarding-nav">
                        <button type="button" class="onboarding-nav-item" @click="goToStep(2)" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(3)" />
                        <button type="button" class="onboarding-nav-item is-active" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(5)" />
                    </div>
                    <div class="onboarding-actions">
                        <button type="button" class="button-default with-arrow colored-black" @click="close" v-text="$t('onboarding.activate_member_moments')" />
                        <button type="button" class="button-default with-arrow colored-purple" @click="nextStep" v-text="$t('onboarding.continue')" />
                    </div>
                </div>
                <div v-else-if="currentStep === 5" key="step-5" class="onboarding-step">
                    <h1 class="onboarding-headline" v-text="$t('onboarding.step_5.headline')" />
                    <div class="onboarding-illustration">
                        <img src="@/assets/img/onboarding-moments-5.png" srcset="@/assets/img/onboarding-moments-5@2x.png 2x" alt="Edit & share" />
                        <ol class="onboarding-numbers">
                            <li v-text="$t('onboarding.step_5.text_1')" />
                            <li v-text="$t('onboarding.step_5.text_2')" />
                        </ol>
                    </div>
                    <div class="onboarding-nav">
                        <button type="button" class="onboarding-nav-item" @click="goToStep(2)" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(3)" />
                        <button type="button" class="onboarding-nav-item" @click="goToStep(4)" />
                        <button type="button" class="onboarding-nav-item is-active" />
                    </div>
                    <div class="onboarding-actions">
                        <button type="button" class="button-default with-arrow colored-black" @click="reject" v-text="$t('onboarding.skip')" />
                        <button type="button" class="button-default with-arrow colored-purple" @click="close" v-text="$t('onboarding.activate_member_moments')" />
                    </div>
                </div>
            </transition>
        </div>
    </overlay>
</template>

<script>
import get from 'lodash/get';
import Overlay from '@/components/Overlay';

export default {
  name: 'OnboardingOverlay',
  components: {
    Overlay,
  },
  data() {
    return {
      currentStep: 1,
    };
  },
  methods: {
    nextStep() {
      this.currentStep++;
    },
    goToStep(step) {
      this.currentStep = step;
    },
    async close() {
      const response = await this.$api.post('profile/onboarding');
      const currentUser = get(response, 'data.data', {});

      this.$store.commit('setUser', currentUser);
      this.$store.commit('setViewingOnboarding', false);
    },
    async reject() {
      const response = await this.$api.post('profile/terms-consent', {
        has_accepted_terms: false,
      });

      const currentUser = get(response, 'data.data', {});

      this.$store.commit('setUser', currentUser);
      this.$store.commit('setAcceptingTerms', { isAcceptingTerms: false });

      this.close();
    },
  },
};
</script>

<style lang="scss">
.onboarding {
  max-width: 800px;
  color: $color-palette-grey-2;
  line-height: 1.5;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
}

.onboarding-step > * + * {
  margin-top: 30px;

  @include breakpoint('desktop') {
    margin-top: 60px;
  }
}

.onboarding-headline {
  font-size: 20px;
  font-weight: normal;

  @include breakpoint('desktop') {
    font-size: 26px;
  }
}

.onboarding-headline,
.onboarding-text {
  text-align: center;
}

.onboarding-headline + .onboarding-text {
  margin-top: 30px;
}

.onboarding-text > * + * {
  margin-top: 24px;
}

.onboarding-border {
  border: 1px solid $color-palette-grey-2;
}

.onboarding-numbers {
  counter-reset: item;
  list-style-type: none;

  li {
    counter-increment: item;
    position: relative;
    padding-left: 43px;

    &:before {
      content: counter(item);
      position: absolute;
      left: 0;
      top: 0;
      width: 28px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      font-weight: bold;
      background: $color-palette-purple-1;
      border-radius: 50%;
    }
  }

  li + li {
    margin-top: 20px;
  }
}

.onboarding-illustration {
  display: grid;
  gap: 15px;
  align-items: center;

  img {
    max-width: 392px;
    margin: 0 auto;
  }

  @include breakpoint('desktop') {
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .onboarding-numbers {
      order: 1;
    }

    img {
      order: 2;
    }
  }
}

.onboarding-nav {
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 10px;
  }
}

.onboarding-nav-item {
  background: none;
  cursor: pointer;
  height: 10px;
  width: 10px;
  border: 1px solid $color-palette-grey-2;
  border-radius: 50%;

  &.is-active {
    background-color: $color-palette-grey-2;
    cursor: default;
  }
}

.onboarding-actions {
  display: grid;
  gap: 15px;

  @include breakpoint('desktop') {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
</style>
