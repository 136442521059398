import api from '@/api';

const state = {
  isLoadingChannels: false,
  channels: [],
};

const getters = {
  channels: state => state.channels,
  hasChannels: state => state.channels.length > 0,
  loadingChannels: state => state.isLoadingChannels,
};

const mutations = {
  setChannels(state, channels) {
    state.channels = channels;
  },
  setChannelsLoading(stage, isLoading) {
    state.isLoadingChannels = isLoading;
  },
};

const actions = {
  async loadChannels({ commit, getters }) {
    if (!getters.hasChannels && !getters.loadingChannels) {
      commit('setChannelsLoading', true);
      let {
        data: { data: channels },
      } = await api.get('channels');
      commit('setChannels', channels);
      commit('setChannelsLoading', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
