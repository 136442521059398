export default {
    trackEvent(data) {
        if (typeof pag_tracker !== "undefined") {
            pag_tracker.event(data.category, data.action, data.label);
        } else {
            console.log(`[GTM] TrackEvent: ${JSON.stringify(data)}`);
        }
    },
    trackView(pageName) {
        if (typeof pag_tracker !== "undefined") {
            pag_tracker.page(pageName);
        } else {
            console.log(`[GTM] TrackView: ${pageName}`);
        }
    },
    trackPayment(transactionID, affiliation, revenue, tax, shipping, currency, couponCode, eventName, eventId, eventPrice, brand, category, variant, qty, coupon) {
        if (typeof pag_tracker !== "undefined") {
            pag_tracker.ecPurchase(transactionID, affiliation, revenue, tax, shipping, currency, couponCode
                    [{
                    'name': eventName,
                    'id': eventId,
                    'price': eventPrice,
                    'brand': brand,
                    'category': category,
                    'variant': variant,
                    'quantity': qty,
                    'coupon': coupon
                }]
            );
        } else {
            console.log(transactionID, affiliation, revenue, tax, shipping, currency, couponCode, eventName, eventId, eventPrice, brand, category, variant, qty, coupon);
        }
    }
}
