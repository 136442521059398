<script>
    export default {
        props: {
            value: {
                type: [Number, String],
                default: '',
            },
            options: {
                type: Array,
                required: true,
            },
            valueKey: {
                type: String,
                default: 'id',
            },
            nameKey: {
                type: String,
                default: 'name',
            },
            required: {
                type: Boolean,
                default: false,
            },
        },
    };
</script>