<template>
    <div class="legal-rights-links" :class="{ 'with-main-nav': withMainNav }">
        <ul v-if="mobileAwaysVisiblePages">
            <li
                v-for="page in mobileAwaysVisiblePages"
                :key="`legal-page-${page.id}`"
            >
                <router-link :to="{ name: 'page', params: { section: page.section, slug: page.slug, pageData: page, } }">
                    {{ page.translations.title }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, } from 'vuex';
import CurrentUserMarketMixin from '@/components/current_user_market_mixin';

export default {
    mixins: [CurrentUserMarketMixin],
    data: function() {
        return {
            withMainNav: false,
        };
    },
    created() {
        if (this.$route.meta.hasMainNav === true) {
            this.withMainNav = true;
        }
    },
    computed: {
        ...mapGetters([
            'mobileAwaysVisiblePages',
        ]),
    },
};
</script>

<style lang="scss">
    .legal-rights-links {
        padding: 20px;
        margin-bottom: 50px;
        text-align: center;

        @include breakpoint('desktop') {
            display: none;
        }

        &.with-main-nav {
            margin-bottom: 100px;
        }

        li {
            display: inline-block;
            border-right: 1px $color-palette-grey-8 solid;
            margin-right: 10px;
            padding-right: 10px;

            &:last-child {
                border-right: none;
                margin-right: 0;
                padding-right: 0;
            }

            a {
                color: $color-palette-grey-4;
                text-decoration: none;
            }
        }
    }
</style>
