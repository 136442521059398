import axios from 'axios';
import store from './vuex/store';
import router from './router';
import { throttleAdapterEnhancer, cacheAdapterEnhancer } from 'axios-extensions';

const BASE_URL = process.env.VUE_APP_API;

axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['User-Time'] = encodeURI(new Date().toString());
axios.defaults.withCredentials = true;

const errorInterceptor = error => {
  switch (error?.response.status) {
    case 401:
      store.commit('setSessionExpired', true);
      store.commit('removeUser');
      store.commit('setPages', []);

      return Promise.reject(error);

    case 403:
      router.push('/403');

      return Promise.reject(error);

    case 404:
      router.push('/404');

      return Promise.reject(error);

    case 422:
      throw {
        status: 422,
        data: error.response.data,
        error: new Error(error),
        errors: error.response.data.errors,
      };

    default:
      throw {
        status: error.response.status,
        data: error.response.data,
        error: new Error(error),
      };
  }
};

const createHttpAdapter = () => {
  const axiosInstance = axios.create({
    //adapter: throttleAdapterEnhancer(cacheAdapterEnhancer(axios.defaults.adapter), {
    //  threshold: 2 * 1000,
    //}),
  });
  axiosInstance.interceptors.response.use(undefined, errorInterceptor);

  return axiosInstance;
};

export var http = createHttpAdapter();

export default {
  get(path, httpOptions) {
    return http.get(this.formatUrl(path), httpOptions);
  },
  getByLang(path, withCache = true) {
    return this.get(path, {
      headers: { 'Language-Id': store.getters.currentLanguage.id },
      cache: withCache,
    });
  },
  post(path, data, httpOptions) {
    return http.post(this.formatUrl(path), data, httpOptions);
  },
  put(path, data, httpOptions) {
    return http.put(this.formatUrl(path), data, httpOptions);
  },
  patch(path, data, httpOptions) {
    return http.patch(this.formatUrl(path), data, httpOptions);
  },
  delete(path, httpOptions) {
    return http.delete(this.formatUrl(path), httpOptions);
  },
  formatUrl(path) {
    return BASE_URL + path;
  },
  startSession() {
    store.commit('setSessionExpired', false);

    localStorage.setItem('hasSession', '1');
    http = createHttpAdapter();
  },
  endSession() {
    localStorage.setItem('hasSession', '0');
    http = createHttpAdapter();
  },
  hasSession() {
    return localStorage.getItem('hasSession') === '1';
  },
};
