<template>
    <select-box
        :name="name"
        :title="title"
        :lang-section="langSection"
        :options="supportCaseOptions"
        :value="value"
        :autocomplete="autocomplete"
        :required="required"
        :name-key="nameKey"
        :wrap-class="wrapClass"
        :value-key="valueKey"
        :element-class="elementClass"
        :placeholder="placeholder"
        :errors="errors"
        @input="updateValue">
    </select-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { SelectBox } from '@/components/form';
    import { ElementMixin, SelectorMixin, ErrorsMixin } from '@/components/form/mixins';

    export default {
        components: {
            SelectBox,
        },
        mixins: [ElementMixin, SelectorMixin, ErrorsMixin],
        props: {
            nameKey: {
                type: String,
                default: 'title',
            },
            name: {
                default: 'support_case_id',
            },
            options: {
                required: false,
            },
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
        },
        computed: {
            ...mapGetters([
                'supportCases',
            ]),
            supportCaseOptions() {
                this.supportCases.forEach((item) => {
                    return item.title = this.$t(`support.${item.name}`);
                });

                return this.supportCases;
            }
        },
    };
</script>