<template>
    <select-box
        :name="name"
        :title="title"
        :lang-section="langSection"
        :options="titleOptions"
        :value="value"
        :autocomplete="autocomplete"
        :required="required"
        :name-key="nameKey"
        :wrap-class="wrapClass"
        :value-key="valueKey"
        :element-class="elementClass"
        :placeholder="placeholder"
        :errors="errors"
        @input="updateValue">
    </select-box>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { SelectBox } from '@/components/form';
    import { ElementMixin, SelectorMixin, ErrorsMixin } from '@/components/form/mixins';

    export default {
        components: {
            SelectBox,
        },
        mixins: [ElementMixin, SelectorMixin, ErrorsMixin],
        props: {
            name: {
                default: 'title_id',
            },
            options: {
                required: false,
            },
            nameKey: {
                default: 'titleTranslation',
            },
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
        },
        computed: {
            ...mapGetters([
                'titles',
            ]),
            titleOptions() {
                if (this.titles.findIndex(item => item.title === 'title') === -1) {
                    this.titles.unshift({
                        id: null,
                        title: 'title',
                    });
                }

                this.titles.forEach((item) => {
                    return item.titleTranslation = this.$t(`${this.langSection}.titles.${item.title}`);
                });

                return this.titles;
            },
        },
    };
</script>
