export function objectToFormData(source, form = new FormData(), parentKey = null) {
  source = source || {};

  for (const key in source) {
    if (Object.prototype.hasOwnProperty.call(source, key)) {
      append(form, composeKey(parentKey, key), source[key]);
    }
  }

  return form;
}

function composeKey(parent, key) {
  return parent ? `${parent}[${key}]` : key;
}

function append(form, key, value) {
  switch (true) {
    case value instanceof Date:
      return form.append(key, value.toISOString());

    case value instanceof File:
      return form.append(key, value, value.name);

    case value instanceof Blob:
    case typeof value === 'string':
      return form.append(key, value);

    case typeof value === 'boolean':
      return form.append(key, value ? '1' : '0');

    case typeof value === 'number':
      return form.append(key, `${value}`);

    case value === null:
    case value === undefined:
      return form.append(key, '');

    default:
      objectToFormData(value, form, key);
      break;
  }
}
