import api from '@/api';

const state = {
  isLoadingMarkets: false,
  markets: [],
};

const getters = {
  markets: state => state.markets,
  hasMarkets: state => state.markets.length > 0,
  loadingMarkets: state => state.isLoadingMarkets,
};

const mutations = {
  setMarkets(state, markets) {
    state.markets = markets;
  },
  setMarketsLoading(state, isLoading) {
    state.isLoadingMarkets = isLoading;
  },
};

const actions = {
  async loadMarkets({ commit, getters }) {
    if (!getters.hasMarkets && !getters.loadingMarkets) {
      commit('setMarketsLoading', true);
      let {
        data: { data: markets },
      } = await api.get('markets');
      commit('setMarkets', markets);
      commit('setMarketsLoading', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
