<template>
    <overlay important center>
        <div class="accept-terms">
            <svg xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76">
                <g fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-width="3" transform="translate(2 2)">
                    <path stroke="#8d57cb" d="M36 0c19.882 0 36 16.118 36 36S55.882 72 36 72 0 55.882 0 36 16.118 0 36 0z" />
                    <path stroke="#FFF" d="M35.998 72.001a35.833 35.833 0 0 0 21.145-6.873c-.421-11.373-9.763-20.375-21.144-20.375-11.38 0-20.722 9.002-21.143 20.375a35.831 35.831 0 0 0 21.142 6.873h0z" />
                    <rect width="18.65" height="22.397" x="26.675" y="17.602" stroke="#FFF" rx="9.325" />
                </g>
            </svg>
            <h1 class="accept-terms-headline" v-text="$t('terms_consent.headline')" />
            <div v-if="isConfirmingRejection" class="accept-terms-text">
                <p v-text="$t('terms_consent.reject_text_1')" />
                <p v-text="$t('terms_consent.reject_text_2')" />
            </div>
            <div v-else class="accept-terms-text">
                <p v-text="$t('terms_consent.text_1')" />
                <p v-text="$t('terms_consent.text_2')" />
                <p v-text="$t('terms_consent.text_3')" />
            </div>
            <form class="accept-terms-form" @submit.prevent="save">
                <div class="custom-checkbox-input-container">
                    <input id="accept" v-model="form.has_accepted_terms" type="checkbox">
                    <label for="accept" class="checkbox">
                        <span class="checkbox">
                            <i />
                        </span>
                        <span class="checkbox-text" v-html="$t('terms_consent.accept_terms', { accept_terms_link: termsLink })" />
                    </label>
                </div>
                <div class="accept-terms-actions">
                    <button type="button" class="button-default with-arrow as-link" :disabled="isSaving || hasAccepted" @click="isConfirmingRejection ? confirmRejection() : reject()" v-text="$t('terms_consent.decline')" />
                    <button type="submit" class="button-default with-arrow colored-purple" :disabled="isSaving || !hasAccepted" v-text="$t('terms_consent.accept')" />
                </div>
            </form>
        </div>
    </overlay>
</template>

<script>
import get from 'lodash/get';
import { mapGetters } from 'vuex';
import CurrentUserMarketMixin from '@/components/current_user_market_mixin';
import Overlay from '@/components/Overlay';

export default {
  name: 'AcceptTermsOverlay',
  components: {
    Overlay,
  },
  mixins: [CurrentUserMarketMixin],
  data() {
    return {
      isConfirmingRejection: false,
      isSaving: false,
      form: {
        has_accepted_terms: false,
      },
    };
  },
  computed: {
    ...mapGetters({
      callback: 'acceptingTermsCallback',
    }),
    hasAccepted() {
      return this.form.has_accepted_terms === true;
    },
    termsLink() {
      const slug = this.currentUserMarket.abbr === 'PCNA' ? 'legal-notice' : 'terms-conditions';
      const route = this.$router.resolve({
        name: 'page',
        params: { section: 'app', slug },
      });

      return `<a href="${route.href}" target="_blank">${this.$t(
        'terms_consent.accept_terms_link',
      )}</a>`;
    },
  },
  methods: {
    reject() {
      this.isConfirmingRejection = true;
    },
    confirmRejection() {
      this.form.has_accepted_terms = false;

      this.save();
    },
    async save() {
      this.isSaving = true;

      const response = await this.$api.post('profile/terms-consent', this.form);
      const currentUser = get(response, 'data.data', {});

      this.$store.commit('setUser', currentUser);

      if (this.callback) {
        await this.callback(this.hasAccepted);
      }

      this.$store.commit('setAcceptingTerms', { isAcceptingTerms: false });
      this.isSaving = false;
      this.isConfirmingRejection = false;
    },
  },
};
</script>

<style lang="scss">
.accept-terms {
  max-width: 800px;
  color: $color-palette-grey-2;
  line-height: 1.5;
  text-align: center;

  a {
    color: $color-palette-grey-2;
  }

  svg {
    display: block;
    margin: 0 auto;
  }

  > * + * {
    margin-top: 30px;
  }
}

.accept-terms-headline {
  font-size: 20px;
  font-weight: normal;

  @include breakpoint('desktop') {
    font-size: 26px;
  }
}

.accept-terms-text > * + * {
  margin-top: 24px;
}

.accept-terms-form > * + * {
  margin-top: 30px;
}

.accept-terms-actions {
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 15px;
  }

  .button-default {
    width: auto;
  }
}

/* Form fields overrides. */
.accept-terms .custom-checkbox-input-container {
  display: inline-block;
  text-align: left;
  margin-bottom: 0;

  input[type='checkbox'] + label i {
    background-color: transparent;
    border: 1px solid $color-palette-white;
  }

  input[type='checkbox']:checked + label i::before {
    background-color: $color-palette-white;
  }
}
</style>
