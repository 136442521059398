<template>
    <div :class="getWrapClass">
        <div v-if="hasError" class="error-text" v-html="getError"></div>
        <input :id="name" type="checkbox" :checked="value" @change="updateChecked">
        <label :for="name" class="checkbox">
            <span class="checkbox">
                <i></i>
            </span>
            <span class="checkbox-text" v-html="formatTitle"></span>
        </label>
    </div>
</template>

<script>
    import { ElementMixin, ErrorsMixin } from './mixins';

    export default {
        mixins: [ElementMixin, ErrorsMixin],
        props: {
            wrapClass: {
                type: String,
                default: 'custom-checkbox-input-container',
            }
        },
        methods: {
            updateChecked(event) {
                this.$emit('input', event.target.checked);
            },
        }
    }
</script>