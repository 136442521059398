<template>
    <overlay important center>
        <div class="expired-session">
            <h1 class="expired-session-headline" v-text="$t('session_expired.heading')" />
            <p v-text="$t('session_expired.body')" />
            <button class="button-default with-arrow colored-purple" @click="$router.push({ name: 'logout' })" v-text="$t('session_expired.button')" />
        </div>
    </overlay>
</template>

<script>
import Overlay from '@/components/Overlay';

export default {
  name: 'ExpiredSessionOverlay',
  components: {
    Overlay,
  },
};
</script>

<style lang="scss">
.expired-session {
  max-width: 350px;
  color: $color-palette-grey-2;
  line-height: 1.5;
  text-align: center;

  > * + * {
    margin-top: 30px;
  }
}

.expired-session-headline {
  font-size: 20px;
  font-weight: normal;

  @include breakpoint('desktop') {
    font-size: 26px;
  }
}
</style>
