import 'url-polyfill';
import 'element-matches-polyfill';

import Vue from 'vue';
import { i18n } from './i18n';
import App from './App.vue';
import router from './router';
import store from './vuex/store';
import VueMoment from 'vue-moment';
import api from './api';
import gtm from './lib/gtm';
import VueLoadScript from 'vue-load-script-plus';
import './directives';

Vue.config.productionTip = false;
Vue.prototype.$api = api;
Vue.prototype.$gtm = gtm;
Vue.use(VueMoment);
Vue.use(VueLoadScript);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');
