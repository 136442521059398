<template>
    <div :class="getWrapClass">
        <div v-if="hasError" class="error-text" v-html="getError"></div>
        <label v-if="!hasError && value" :for="name" v-html="formatTitle"></label>
        <textarea
            :id="name"
            :value="value"
            :disabled="disabled"
            :class="elementClass"
            :autocomplete="getAutocomplete"
            :placeholder="getPlaceholder"
            @input="updateValue">
        </textarea>
    </div>
</template>

<script>
    import { ElementMixin, ErrorsMixin } from './mixins';

    export default {
        mixins: [ElementMixin, ErrorsMixin],
        methods: {
            updateValue(event) {
                this.$emit('input', event.target.value);
            },
        },
    }
</script>