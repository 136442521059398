var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
            'info-link-container',
            { pcna: _vm.currentUserMarket && _vm.currentUserMarket.abbr === 'PCNA' },
        ]},[_c('div',{staticClass:"copyright-wrap"},[_c('p',{staticClass:"copyright",domProps:{"innerHTML":_vm._s(_vm.copyright)}}),_vm._l((_vm.appPages),function(page){return _c('router-link',{key:`page-${page.id}`,attrs:{"to":{
                    name: 'page',
                    params: { section: page.section, slug: page.slug, pageData: page },
                }}},[_vm._v(" "+_vm._s(page.translations.title)+" ")])}),(!_vm.currentUserMarket)?_c('div',[_c('router-link',{attrs:{"to":"/page/app/imprint"}},[_vm._v(_vm._s(_vm.$t('navigation.imprint')))]),_c('router-link',{attrs:{"to":"/page/app/privacy-policy"}},[_vm._v(_vm._s(_vm.$t('navigation.data_protection_policy')))]),_c('router-link',{attrs:{"to":"/page/app/terms-conditions"}},[_vm._v(_vm._s(_vm.$t('navigation.terms')))]),_c('router-link',{attrs:{"to":"/page/app/third-party-licences"}},[_vm._v(_vm._s(_vm.$t('navigation.third_party_licences')))]),_c('router-link',{attrs:{"to":"/page/app/cookie-policy"}},[_vm._v("Cookie Policy")])],1):_vm._e(),(_vm.currentUserMarket && _vm.currentUserMarket.abbr == 'PCNA' || _vm.showPCNA)?_c('a',{staticClass:"button-default with-arrow custom-color",attrs:{"href":"https://www.porsche.com/usa/privacy-policy/contact/","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('footer.pcna_button'))+" ")]):_vm._e()],2)]),(
            (!_vm.currentUserMarket && !_vm.showPCNA) ||
                (_vm.currentUserMarket && _vm.currentUserMarket.abbr !== 'PCNA')
        )?_c('div',[_c('p',[_vm._v(_vm._s(_vm.$t('footer.footer_p1')))]),_c('p',[_vm._v(_vm._s(_vm.$t('footer.footer_p2')))]),_c('p',[_vm._v(_vm._s(_vm.$t('footer.footer_p3')))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }