import api from '@/api';
import sortBy from 'lodash/sortBy';

const state = {
  isLoadingPages: false,
  pages: [],
};

const getters = {
  allPages: state => state.pages,
  hasPages: state => state.pages.length > 0,
  appPages: state => sortBy(state.pages.filter(page => page.section === 'app'), ['order']),
  bookingPages: state => sortBy(state.pages.filter(page => page.section === 'booking'), ['order']),
  mobileAwaysVisiblePages: state =>
    sortBy(state.pages.filter(page => page.mobile_aways_visible), ['order']),
  loadingPages: state => state.isLoadingPages,
};

const mutations = {
  setPages(state, pages) {
    state.pages = pages;
  },
  setPagesLoading(state, isLoading) {
    state.isLoadingPages = isLoading;
  },
};

const actions = {
  async loadPages({ commit, getters }) {
    if (!getters.hasPages && !getters.loadingPages) {
      commit('setPagesLoading', true);
      let {
        data: { data: pages },
      } = await api.getByLang('pages');
      commit('setPages', pages);
      commit('setPagesLoading', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
