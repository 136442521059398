const state = {
  booking_footer: false,
};

const getters = {
  isBookingFooter: state => state.booking_footer === true,
};

const mutations = {
  setBookingFooter(state, isBookingFooter) {
    state.booking_footer = isBookingFooter;
  },
};

export default {
  state,
  getters,
  mutations,
};
