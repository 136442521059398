import api from '@/api';
import isEmpty from 'lodash/isEmpty';

const state = {
  languages: [],
  isLoadingLanguages: false,
  currentLanguage: {},
  isSettingCurrentLanguage: false,
};

const getters = {
  loadingLanguages: state => state.isLoadingLanguages,
  hasLanguages: state => state.languages.length > 0,
  languages: state => state.languages,
  settingCurrentLanguage: state => state.isSettingCurrentLanguage,
  hasCurrentLanguage: state => !isEmpty(state.currentLanguage),
  currentLanguage: state => state.currentLanguage,
};

const mutations = {
  setLanguage(state, languages) {
    state.languages = languages;
  },
  setLoadingLanguages(state, isLoadingLanguages) {
    state.isLoadingLanguages = isLoadingLanguages;
  },
  setCurrentLanguage(state, language) {
    state.currentLanguage = language;
  },
  setSettingCurrentLanguage(state, isSettingCurrentLanguage) {
    state.isSettingCurrentLanguage = isSettingCurrentLanguage;
  },
};

const actions = {
  async loadLanguages({ commit, getters }) {
    commit('setLanguage', JSON.parse(localStorage.getItem('languages')) || []);

    if (!getters.hasLanguages && !getters.loadingLanguages) {
      commit('setLoadingLanguages', true);
      let {
        data: { data: languages },
      } = await api.get('languages');
      await localStorage.setItem('languages', JSON.stringify(languages));
      commit('setLanguage', languages);
      commit('setLoadingLanguages', false);
    }
  },
  async setCurrentLanguage({ commit, getters }) {
    var lang;
    try {
      lang = JSON.parse(localStorage.getItem('currentLanguage')) || {};
    } catch (e) {
      lang = {};
    }
    commit('setCurrentLanguage', lang);

    if (!getters.hasCurrentLanguage && !getters.settingCurrentLanguage) {
      commit('setSettingCurrentLanguage', true);
      let currentLanguage = getters.languages.find(item => item.code === 'en_EN');
      localStorage.setItem('currentLanguage', JSON.stringify(currentLanguage));
      commit('setCurrentLanguage', currentLanguage);
      commit('setSettingCurrentLanguage', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
