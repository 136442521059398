<template>
    <select-box
        :name="name"
        :title="title"
        :lang-section="langSection"
        :options="genderOptions"
        :value="value"
        :autocomplete="autocomplete"
        :required="required"
        :name-key="nameKey"
        :wrap-class="wrapClass"
        :value-key="valueKey"
        :element-class="elementClass"
        :placeholder="placeholder"
        :errors="errors"
        @input="updateValue">
    </select-box>
</template>

<script>
    import { SelectBox } from '@/components/form';
    import { ElementMixin, SelectorMixin, ErrorsMixin } from '@/components/form/mixins';

    export default {
        components: {
            SelectBox,
        },
        mixins: [ElementMixin, SelectorMixin, ErrorsMixin],
        props: {
            name: {
                default: 'gender',
            },
            options: {
                required: false,
            },
        },
        computed: {
            genderOptions() {
                return [
                    { id: 'male', name: this.$t(`${this.langSection}.genders.male`), },
                    { id: 'female', name: this.$t(`${this.langSection}.genders.female`), },
                    { id: 'other', name: this.$t(`${this.langSection}.genders.other`), },
                ];
            }
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
        },
    };
</script>