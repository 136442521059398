import Vue from 'vue';

Vue.directive('focus', {
  inserted: (el, binding) => {
    if (el.offsetParent && (!Object.prototype.hasOwnProperty.call(binding, 'value') || binding.value)) {
      el.focus();
    }
  },
});

Vue.directive('outside', {
  bind: (el, binding) => {
    el.clickOutsideEvent = event => {
      const path = event.path || (event.composedPath && event.composedPath());
      const isClickOutside = path ? path.indexOf(el) < 0 : !el.contains(event.target);
      if (isClickOutside) {
        binding.value(event, el);
      }
    };

    document.body.addEventListener('mousedown', el.clickOutsideEvent);
  },
  unbind: el => {
    document.body.removeEventListener('mousedown', el.clickOutsideEvent);
  },
});
