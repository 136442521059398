<template>
    <overlay important center>
        <div class="change-password">
            <div v-if="isSaved" class="change-password-confirmation">
                <button type="button" class="change-password-close-button" @click="close">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="33" height="33" viewBox="0 0 33 33">
                        <defs>
                            <filter id="close-icon-a" width="148%" height="148.6%" x="-24%" y="-24.3%" filterUnits="objectBoundingBox">
                                <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
                                <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="2" />
                                <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0" />
                            </filter>
                            <polygon id="close-icon-b" points="0 24.669 2.643 24.669 12.5 13.793 22.357 24.668 25 24.668 13.822 12.334 25 0 22.357 0 12.5 10.875 2.643 0 0 0 11.179 12.334" />
                        </defs>
                        <g fill="none" transform="translate(4 4)">
                            <use fill="#000" filter="url(#close-icon-a)" xlink:href="#close-icon-b" />
                            <use fill="#FFF" xlink:href="#close-icon-b" />
                        </g>
                    </svg>
                </button>
                <img src="@/assets/svg/password-save.svg" alt="" />
                <h1 class="change-password-headline" v-text="$t('change_password.confirm_headline')" />
                <p class="change-password-text" v-html="$t('change_password.confirm_body')" />
            </div>
            <div v-else class="change-password-prompt">
                <img src="@/assets/svg/password-save.svg" alt="" />
                <h1 class="change-password-headline" v-text="$t('change_password.title')" />
                <p v-text="$t('change_password.intro')" />
                <ul class="change-password-rules">
                    <li v-text="$t('change_password.rule_1')" />
                    <li v-text="$t('change_password.rule_2')" />
                </ul>
                <p v-text="$t('change_password.thank_you')" />
                <form class="change-password-form" @submit.prevent="save">
                    <password-input
                        v-model="form.current_password"
                        name="current_password"
                        lang-section="change_password"
                        type="password"
                        :errors="errors"
                        autocomplete="password"
                        required
                    />
                    <password-input
                        v-model="form.password"
                        name="password"
                        lang-section="change_password"
                        type="password"
                        :errors="errors"
                        autocomplete="new-password"
                        required
                    />
                    <password-input
                        v-model="form.password_confirmation"
                        name="password_confirmation"
                        lang-section="change_password"
                        type="password"
                        autocomplete="new-password"
                        :errors="errors"
                        required
                    />
                    <button type="submit" class="button-default with-arrow colored-purple" :disabled="isSaving" v-text="$t('change_password.change_password')" />
                </form>
            </div>
        </div>
    </overlay>
</template>

<script>
import get from 'lodash/get';
import Overlay from '@/components/Overlay';
import { PasswordInput } from '@/components/form';

export default {
  name: 'ChangePasswordOverlay',
  components: {
    Overlay,
    PasswordInput,
  },
  data() {
    return {
      isSaving: false,
      isSaved: false,
      form: {
        current_password: null,
        password: null,
        password_confirmation: null,
      },
      errors: {},
    };
  },
  methods: {
    async save() {
      this.isSaving = true;

      try {
        const response = await this.$api.post('profile/password', this.form);
        const currentUser = get(response, 'data.data', {});

        this.$store.commit('setUser', currentUser);
        this.isSaved = true;
      } catch ({ status, errors }) {
        if (status === 422) {
          this.errors = errors;
        }
      }

      this.isSaving = false;
    },
    close() {
      this.$store.commit('setChangingPassword', false);
    },
  },
};
</script>

<style lang="scss">
.change-password {
  max-width: 350px;
  color: $color-palette-grey-2;
  line-height: 1.5;

  img {
    display: block;
    margin: 0 auto;
  }
}

.change-password-prompt > * + *,
.change-password-form > * + *,
.change-password-confirmation > * + * {
  margin-top: 30px;
}

.change-password-rules {
  list-style: disc;
  list-style-position: outside;
  margin-left: 20px;

  li {
    font-weight: bold;
  }

  li + li {
    margin-top: 10px;
  }
}

.change-password-headline {
  font-size: 20px;
  font-weight: normal;
  text-align: center;

  @include breakpoint('desktop') {
    font-size: 26px;
  }
}

.change-password-text {
  text-align: center;
}

.change-password-close-button {
  position: absolute;
  right: 20px;
  top: 20px;
  background: none;
  color: inherit;
  cursor: pointer;
}

/* Form fields override. */
.change-password input.main-input {
  max-width: none;
  margin-bottom: 0;
}
</style>
