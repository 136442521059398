<script>
    export default {
        props: {
            value: {},
            name: {
                type: String,
                required: true,
            },
            title: {
                type: String,
                default: '',
            },
            langSection: {
                type: String,
                default: '',
            },
            autocomplete: {
                type: String,
                default: '',
            },
            placeholder: {
                type: String,
                default: '',
            },
            required: {
                type: Boolean,
                default: false,
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            wrapClass: {
                type: String,
                default: 'input-container',
            },
            elementClass: {
                type: String,
                default: 'main-input',
            },
        },
        computed: {
            getTitle() {
                if (this.title !== '') {
                    return this.title;
                }

                return this.langSection !== '' ? this.$t(this.langSection+'.'+this.name) :  this.$t(this.name);
            },
            getAutocomplete() {
                return this.autocomplete !== '' ? this.autocomplete : this.name;
            },
            getPlaceholder() {
                return this.placeholder !== '' ? this.placeholder : this.formatTitle;
            },
            formatTitle() {
                let title = this.getTitle;

                return this.required ? title+'*' : title;
            },
        }
    }
</script>