<template>
    <header>
        <nav class="main-top-nav clearfix">
            <ul>
                <li>
                    <span class="profile-icon"><img src="@/assets/img/profile-icon@2x.png" alt=""></span>
                    <router-link :to="{ name: 'profile' }" v-text="currentUser.full_name"></router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'support' }">{{ $t('navigation.contact') }}</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'welcome' }">{{ $t('navigation.the_porsche_circle_video_top') }}</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'settings' }">{{ $t('navigation.settings') }}</router-link>
                </li>
                <li>
                    <router-link :to="{ name: 'logout' }">{{ $t('navigation.logout') }}</router-link>
                </li>
            </ul>
        </nav>
        <logo />
    </header>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/common/Logo';

export default {
  components: {
    Logo,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
};
</script>

<style lang="scss">
.main-top-nav {
  display: none;

  @include breakpoint('desktop') {
    display: block;
    background-color: $color-palette-grey-top-nav;
    height: 28px;

    ul {
      float: right;

      li {
        display: inline-block;
        margin-right: 20px;
        vertical-align: middle;
      }
    }
    a {
      color: $color-palette-black;
      font-size: 14px;
      line-height: 28px;
      text-decoration: none;

      &:hover {
        color: $color-palette-purple-1;
      }
    }
    .profile-icon {
      display: inline-block;
      vertical-align: middle;
      margin-right: 5px;
      width: 16px;

      img {
        max-width: 100%;
      }
    }
  }
}
</style>
