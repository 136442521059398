import api from '@/api';

const state = {
  isLoadingEventPrebooking: false,
  eventPrebooking: [],
};

const getters = {
  eventPrebooking: state => state.eventPrebooking,
  hasEventPrebooking: state => state.eventPrebooking.length > 0,
  loadingEventPrebooking: state => state.isLoadingEventPrebooking,
};

const mutations = {
  setEventPrebooking(state, eventPrebooking) {
    state.eventPrebooking = eventPrebooking;
  },
  addToEventPrebooking(state, event) {
    state.eventPrebooking.push(event);
  },
  setEventPrebookingLoading(stage, isLoading) {
    state.isLoadingEventPrebooking = isLoading;
  },
};

const actions = {
  async loadEventPrebooking({ commit, getters }) {
    if (!getters.hasEventPrebooking && !getters.loadingEventPrebooking) {
      commit('setEventPrebookingLoading', true);
      let { data: eventPrebooking } = await api.get('me/prebooking');
      commit('setEventPrebooking', eventPrebooking);
      commit('setEventPrebookingLoading', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
