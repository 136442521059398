import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en_EN from '@/lang/en_EN';
import de_DE from '@/lang/de_DE';

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'en_EN',
    fallbackLocale: 'en_EN',
    messages: { en_EN, de_DE }
});
