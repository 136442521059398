import api from '@/api';

const state = {
  salutations: {
    isLoading: false,
    data: [],
  },
};

const getters = {
  salutations: state => state.salutations.data,
  hasSalutations: state => state.salutations.data.length > 0,
  isLoadingSalutations: state => state.salutations.isLoading,
};

const mutations = {
  setSalutations(state, salutations) {
    state.salutations.data = salutations;
  },
  setLoadingSalutations(state, isLoading) {
    state.salutations.isLoading = isLoading;
  },
};

const actions = {
  async loadSalutations({ getters, commit }) {
    // try loading from localStorage
    commit('setSalutations', JSON.parse(localStorage.getItem('salutations')) || []);

    // load from API
    if (!getters.hasSalutations && !getters.isLoadingSalutations) {
      commit('setLoadingSalutations', true);
      let {
        data: { data: salutations },
      } = await api.get('salutations');
      commit('setSalutations', salutations);
      await localStorage.setItem('salutations', JSON.stringify(salutations));
      commit('setLoadingSalutations', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
