<template>
    <div class="footer">
        <div class="wrapper">
            <LocaleChange></LocaleChange>
            <div class="footer-content">
                <booking-footer v-if="isBookingFooter" />
                <app-footer v-else />
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import LocaleChange from '@/components/common/LocaleChange';
    import AppFooter from './partials/AppFooter.vue';
    import BookingFooter from './partials/BookingFooter.vue';

    export default {
        components: {
            LocaleChange,
            AppFooter,
            BookingFooter,
        },
        computed: {
            ...mapGetters([
                'isBookingFooter',
            ]),
        },
    }
</script>

<style lang="scss">
    .footer {
        display: none;

        @include breakpoint('desktop') {
            display: block;
            background-color: $color-palette-grey-10;
            color: $color-palette-white;

            .wrapper {
                width: 100%;
                max-width: 1190px;
                margin: 0 auto;
            }

            .footer-content {
                color: $color-palette-white;
                font-size: 14px;
                line-height: 1.5;
                padding: 0 0 50px;

                ul {
                    display: table;
                    margin-top: 20px;

                    li {
                        display: table-cell;
                        vertical-align: top;
                        padding-right: 50px;

                        &:last-child {
                            padding-right: 0;
                        }
                    }
                }

                p {
                    margin: 20px 0;
                }

                a {
                    color: $color-palette-white;
                    padding-right: 5px;

                    &:hover {
                        text-decoration: none;
                        color: $color-palette-purple-1;
                    }
                }
            }
        }
    }
</style>
