<template>
    <div>
        <div
            :class="[
                'info-link-container',
                { pcna: currentUserMarket && currentUserMarket.abbr === 'PCNA' },
            ]"
        >
            <div class="copyright-wrap">
                <p class="copyright" v-html="copyright"></p>
                <router-link
                    v-for="page in appPages"
                    :key="`page-${page.id}`"
                    :to="{
                        name: 'page',
                        params: { section: page.section, slug: page.slug, pageData: page },
                    }"
                >
                    {{ page.translations.title }}
                </router-link>
                <div v-if="!currentUserMarket">
                    <router-link to="/page/app/imprint">{{ $t('navigation.imprint') }}</router-link>
                    <router-link to="/page/app/privacy-policy">{{ $t('navigation.data_protection_policy') }}</router-link>
                    <router-link to="/page/app/terms-conditions">{{ $t('navigation.terms') }}</router-link>
                    <router-link to="/page/app/third-party-licences">{{ $t('navigation.third_party_licences') }}</router-link>
                    <router-link to="/page/app/cookie-policy">Cookie Policy</router-link>
                </div>
                <a
                    v-if="currentUserMarket && currentUserMarket.abbr == 'PCNA' || showPCNA"
                    href="https://www.porsche.com/usa/privacy-policy/contact/"
                    target="_blank"
                    class="button-default with-arrow custom-color"
                >
                    {{ $t('footer.pcna_button') }}
                </a>
            </div>
        </div>
        <div
            v-if="
                (!currentUserMarket && !showPCNA) ||
                    (currentUserMarket && currentUserMarket.abbr !== 'PCNA')
            "
        >
            <p>{{ $t('footer.footer_p1') }}</p>
            <p>{{ $t('footer.footer_p2') }}</p>
            <p>{{ $t('footer.footer_p3') }}</p>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CurrentUserMarketMixin from '@/components/current_user_market_mixin';

export default {
  mixins: [CurrentUserMarketMixin],
  data() {
    return {
      copyrightData: {
        default: this.$t('footer.default_copyright', {
          year: new Date().getFullYear(),
        }),
        market: {
          PCNA: this.$t('footer.pcna_copyright', {
            year: new Date().getFullYear(),
          }),
        },
      },
      // this default value does not work as expected
      showPCNA: this.currentUserMarket && this.currentUserMarket.abbr === 'PCNA'
    };
  },
  computed: {
    ...mapGetters(['appPages']),
    copyright() {
      if (
        this.currentUserMarket &&
        this.copyrightData.market[this.currentUserMarket.abbr]
      ) {
        return this.copyrightData.market[this.currentUserMarket.abbr];
      }

      return this.copyrightData.default;
    },
  },
};
</script>

<style lang="scss">
.info-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;

  .copyright-wrap{
    width: 100%;
  }

  .button-default.custom-color {
    float: right;
    top: -50px;
    max-width: 290px;
    margin-left: 20px;
    background-color: $color-palette-purple-2;

    &:hover {
      background-color: $color-palette-purple-1;
      color: #fff;
    }
    &:active {
      background-color: $color-palette-purple-4;
    }
  }

  @media only screen and (max-width: 1024px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .copyright-wrap {
      margin-bottom: 20px;

      a {
        display: none;
      }
    }

    .button-default.custom-color {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>
