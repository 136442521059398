<template>
    <main id="app">
        <component :is="layoutName" v-if="isInitiated">
            <transition name="page" mode="out-in">
                <router-view />
            </transition>
        </component>
        <loader v-if="!isInitiated" />
    </main>
</template>

<script>
const defaultLayout = 'guest';

import { mapGetters, mapActions } from 'vuex';
import Loader from '@/components/common/Loader';

export default {
  components: {
    Loader,
  },
  data() {
    return {
      isInitiated: false,
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    layoutName() {
      let layoutName = defaultLayout;

      if (this.$route.meta.layout) {
        if (this.$route.meta.layout === 'dynamic') {
          layoutName = this.$api.hasSession() ? 'auth' : 'guest';
        } else {
          layoutName = this.$route.meta.layout;
        }
      }

      return `${layoutName}-layout`;
    },
  },
  created() {
    this.init();
    this.$route.meta.hasMainNav = this.$api.hasSession();

    this.$store.subscribe(mutation => {
      if (mutation.type === 'setCurrentLanguage') {
        if (this.$i18n) {
          this.$i18n.locale = mutation.payload.code;
        }
      }
    });
  },
  methods: {
    ...mapActions([
      'loadLanguages',
      'setCurrentLanguage',
      'loadCountries',
      'loadSalutations',
      'loadSupportCases',
      'loadTitles',
    ]),

    async init() {
      await Promise.all([
        this.loadLanguages(),
        this.loadCountries(),
        this.loadSalutations(),
        this.loadSupportCases(),
        this.loadTitles(),
      ]);
      await this.setCurrentLanguage();
      this.isInitiated = true;

      if (process.env.NODE_ENV === 'production') {
        this.loadGoogleTagManagerIntegration();
      }
    },
    loadGoogleTagManagerIntegration() {
      let lang = this.formatLang(this.currentLanguage.code);
      (function() {
        let gas = document.createElement('script');
        gas.type = 'text/plain';
        gas.setAttribute('data-usercentrics', 'Google Tag Manager');
        let gad = document.createTextNode(`var ga_data = [{
                        'page': [{
                            'area': 'portal',
                            'pool': 'INT',
                            'country': 'international',
                            'lang': '${lang}',
                            'name': 'porsche-circle',
                            'dealer': '',
                            'trackPageview' : 'false'
                        }]
                    }];`);
        gas.appendChild(gad);
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(gas, s);
      })();
      (function() {
        let ga = document.createElement('script');
        ga.type = 'text/plain';
        ga.setAttribute('data-usercentrics', 'Google Analytics');
        ga.async = true;
        ga.src = '//files.porsche.com/script/tag.min.js';
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(ga, s);
      })();
    },
    formatLang(langCode) {
      switch (langCode) {
        case 'en_EN':
          return 'en';
        case 'de_DE':
          return 'de';
        default:
          return 'en';
      }
    },
  },
};
</script>

<style lang="scss">
@import './porsche-ui-kit/src/common/reset.scss';
@import './assets/scss/_global.scss';
@import './assets/scss/_transitions.scss';

.page-enter-active,
.page-leave-active {
  transition: opacity 0.1s;
}
.page-enter,
.page-leave-to {
  opacity: 0;
}
</style>
