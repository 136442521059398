<template>
    <div :class="getWrapClass">
        <div v-if="hasError" class="error-text" v-html="getError"></div>
        <label v-if="!hasError && val" :for="name" v-html="formatTitle"></label>
        <date-picker
            :value="val"
            width="100%"
            :type="type"
            :editable="false"
            :placeholder="getPlaceholder"
            :clearable="!required"
            :time-picker-options="timePickerOptions"
            :not-before="notBefore"
            :not-after="notAfter"
            :format="getFormat()"
            :lang="datePickerLanguage"
            @input="updateValue"
        />
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { ElementMixin, ErrorsMixin } from './mixins';
import { mapGetters } from 'vuex';

export default {
  components: {
    DatePicker,
  },
  mixins: [ElementMixin, ErrorsMixin],
  data() {
    return {
      datePickerLanguage: 'en',
      currentLang: '',
      val: '',
    };
  },
  created() {
    this.currentLang = this.currentLanguage.code;
    this.datePickerLanguage = this.getLanguage();
    this.val = this.value;
  },
  computed: {
    ...mapGetters(['currentLanguage']),
  },
  props: {
    type: {
      type: String,
      default: 'date',
    },
    format: {
      type: String,
      default: '',
    },
    timePickerOptions: {
      type: [Object, Function],
      default() {
        return {
          start: '00:00',
          step: '00:30',
          end: '23:30',
        };
      },
    },
    notBefore: {
      default: () => new Date(),
    },
    notAfter: {
      default: null,
    },
  },
  methods: {
    updateValue(value) {
      this.val = value;
      this.$emit('input', this.$moment(value).format('YYYY-MM-DD HH:mm'));
    },
    getLanguage() {
      let lang = '';
      if (this.currentLang === 'en_EN') {
        lang = 'en';
      } else if (this.currentLang === 'de_DE') {
        lang = 'de';
      }
      return lang;
    },
    getFormat() {
      if (this.format !== '') {
        return this.format;
      } else {
        let format = '';

        switch (this.type) {
          case 'datetime':
            if (this.currentLang === 'en_EN') {
              format = 'YYYY-MM-DD HH:mm';
            } else if (this.currentLang === 'de_DE') {
              format = 'DD-MM-YYYY HH:mm';
              DatePicker.fecha.i18n.monthNames = [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
              ];
            }
            break;
          default:
            if (this.currentLang === 'en_EN') {
              format = 'YYYY-MM-DD';
            } else if (this.currentLang === 'de_DE') {
              format = 'DD. MMMM YYYY';
              DatePicker.fecha.i18n.monthNames = [
                'Januar',
                'Februar',
                'März',
                'April',
                'Mai',
                'Juni',
                'Juli',
                'August',
                'September',
                'Oktober',
                'November',
                'Dezember',
              ];
            }
            break;
        }

        return format;
      }
    },
  },
};
</script>

<style lang="scss">
.mx-datepicker {
  color: $color-palette-grey-9 !important;
  height: 50px;
  margin-bottom: 40px;

  .mx-input {
    border: none;
    border-radius: 0;
    height: 50px;
    background-color: $color-palette-grey-9 !important;
    color: $color-palette-white !important;
  }

  .mx-input-append {
    background-color: $color-palette-grey-9 !important;
  }
  .mx-calendar-icon {
    color: $color-palette-white !important;
  }
}
.mx-datepicker-popup {
  background-color: $color-palette-grey-9 !important;

  .mx-calendar {
    color: $color-palette-grey-5 !important;
  }
  .mx-panel-date td.today {
    color: $color-palette-status-purple !important;
  }
  .mx-calendar-content .cell:hover {
    background-color: $color-palette-grey-7 !important;
  }
  .mx-calendar-content .cell.actived {
    background-color: $color-palette-status-purple !important;
    color: $color-palette-white !important;
  }
  .mx-calendar-content .cell.disabled {
    background-color: $color-palette-grey-5 !important;
    color: $color-palette-black !important;
  }
}
</style>
