<template>
    <overlay important center>
        <div class="subscribe-newsletter">
            <img src="@/assets/img/icon-mail-purple.png" alt="">
            <h1 class="change-password-headline" v-text="$t('newsletter.heading')" />
            <form class="subscribe-newsletter-form" @submit.prevent="save">
                <check-box
                    v-model="form.newsletter_subscription"
                    name="checkbox_text"
                    lang-section="newsletter"
                />
                <button type="submit" class="button-default with-arrow colored-purple" :disabled="isSaving || !hasAccepted" v-text="$t('newsletter.confirm_button')" />
                <button type="button" class="button-default with-arrow colored-black" :disabled="isSaving || hasAccepted" @click="reject" v-text="$t('newsletter.decline_button')" />
            </form>
            <p class="subscribe-newsletter-legal-text">
                <span v-text="$t('newsletter.footer_info')" />
                <router-link :to="{ name: 'page', params: { section: 'app', slug: 'privacy-policy' } }" v-text="$t('newsletter.data_policy')" />
            </p>
        </div>
    </overlay>
</template>

<script>
import get from 'lodash/get';
import Overlay from '@/components/Overlay';
import { CheckBox } from '@/components/form';

export default {
  name: 'SubscribeNewsletterOverlay',
  components: {
    Overlay,
    CheckBox,
  },
  data() {
    return {
      isSaving: false,
      form: {
        newsletter_subscription: false,
      },
    };
  },
  computed: {
    hasAccepted() {
      return this.form.newsletter_subscription === true;
    },
  },
  methods: {
    reject() {
      this.form.newsletter_subscription = false;

      this.save();
    },
    async save() {
      this.isSaving = true;

      const response = await this.$api.post('profile/settings', this.form);
      const currentUser = get(response, 'data.data', {});

      this.$store.commit('setUser', currentUser);
      this.$store.commit('setSubscribingNewsletter', false);
      this.isSaving = false;
    },
  },
};
</script>

<style lang="scss">
.subscribe-newsletter {
  max-width: 350px;
  color: $color-palette-grey-2;
  line-height: 1.5;

  a {
    color: $color-palette-grey-2;
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

.subscribe-newsletter > * + *,
.subscribe-newsletter-form > * + * {
  margin-top: 30px;
}

.subscribe-newsletter-headline {
  font-size: 20px;
  font-weight: normal;
  text-align: center;

  @include breakpoint('desktop') {
    font-size: 26px;
  }
}

.subscribe-newsletter-legal-text {
  color: $color-palette-grey-2;
  font-size: 12px;
}

/* Form fields overrides. */
.subscribe-newsletter .custom-checkbox-input-container {
  margin-bottom: 0;

  input[type='checkbox'] + label i {
    background-color: transparent;
    border: 1px solid $color-palette-white;
  }

  input[type='checkbox']:checked + label i::before {
    background-color: $color-palette-white;
  }
}
</style>
