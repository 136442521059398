<template>
    <div :class="getWrapClass">
        <div v-if="hasError" class="error-text" v-html="getError"></div>
        <label v-if="!hasError && value" :for="name" v-html="formatTitle" />
        <input
            :id="name"
            :value="value"
            :type="type"
            :disabled="disabled"
            :class="elementClass"
            :min="min"
            :step="step"
            :autocomplete="getAutocomplete"
            :placeholder="getPlaceholder"
            @input="updateValue"
            @keypress="keyPressEvent($event)"
        />
    </div>
</template>

<script>
import { ElementMixin, ErrorsMixin } from './mixins';

export default {
  mixins: [ElementMixin, ErrorsMixin],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    min: {
      type: Number,
      required: false,
    },
    step: {
      type: Number,
      required: false,
    },
  },
  methods: {
    keyPressEvent(event) {
      this.$emit('keypress', event);
    },
    updateValue(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>
