import api from '@/api';

const state = {
  isLoadingEventWaitingList: false,
  eventWaitingList: [],
};

const getters = {
  eventWaitingList: state => state.eventWaitingList,
  hasEventWaitingList: state => state.eventWaitingList.length > 0,
  loadingEventWaitingList: state => state.isLoadingEventWaitingList,
};

const mutations = {
  setEventWaitingList(state, eventWaitingList) {
    state.eventWaitingList = eventWaitingList;
  },
  addToEventWaitingList(state, event) {
    state.eventWaitingList.push(event);
  },
  setEventWaitingListLoading(stage, isLoading) {
    state.isLoadingEventWaitingList = isLoading;
  },
};

const actions = {
  async loadEventWaitingList({ commit, getters }) {
    if (!getters.hasEventWaitingList && !getters.loadingEventWaitingList) {
      commit('setEventWaitingListLoading', true);
      let { data: eventWaitingList } = await api.get('me/event-waiting-list');
      commit('setEventWaitingList', eventWaitingList);
      commit('setEventWaitingListLoading', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
