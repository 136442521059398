<script>
export default {
  props: {
    errors: {
      type: Object,
    },
  },
  computed: {
    getWrapClass() {
      let classes = [];
      if (this.wrapClass !== undefined) {
        classes.push(this.wrapClass);
      }
      if (this.hasError) {
        classes.push('error');
      }
      return classes.join(' ');
    },
    hasError() {
      return (
        typeof this.errors === 'object' && this.errors[this.name] !== undefined
      );
    },
    getError() {
      // return this.hasError ? '<p>'+this.errors[this.name].join("</p><p>")+'</p>' : '';
      return this.hasError ? this.errors[this.name][0] : ''; // we have space only for one error at time
    },
  },
  watch: {
    value: function () {
      if (this.hasError) {
        this.errors[this.name] = undefined;
        delete this.errors[this.name];
      }
    },
  },
};
</script>
