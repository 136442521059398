<template>
    <div class="phone-input-container">
        <text-input
            :name="name"
            :lang-section="langSection"
            :value="value"
            :errors="errors"
            :autocomplete="autocomplete"
            :required="required"
            @input="updateValue"
            @keypress="isNumber">
        </text-input>
    </div>
</template>

<script>
    import { TextInput } from '@/components/form';
    import { ElementMixin, ErrorsMixin } from '@/components/form/mixins';

    export default {
        components: {
            TextInput,
        },
        mixins: [ElementMixin, ErrorsMixin],
        props: {

        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
            isNumber(event) {
                if ([0,1,2,3,4,5,6,7,8,9,"-","+"].findIndex((number) => number.toString() === event.key) > -1) {
                    return true;
                } else {
                    event.preventDefault();
                }
            },
        },
    }
</script>
