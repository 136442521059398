<template>
    <transition name="fade">
        <div class="overlay-container" :class="{ 'overlay-container-important': important }">
            <div class="overlay-background" />
            <div class="overlay" :class="{ 'overlay-center': center }">
                <div class="overlay-content">
                    <slot />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import get from 'lodash/get';

export default {
  name: 'Overlay',
  props: {
    important: {
      type: Boolean,
    },
    center: {
      type: Boolean,
    },
  },
  created() {
    document.body.classList.add('overlay-open');
  },
  beforeDestroy() {
    document.body.classList.remove('overlay-open');
  },
  methods: {
    get,
  },
};
</script>

<style lang="scss">
body.overlay-open {
  overflow: hidden;
}

.overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 97;
  overflow: hidden;
}

.overlay-container-important {
  z-index: 9999;
}

.overlay-background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('@/assets/img/blurred-background@2x.png');
  background-size: cover;
}

.overlay {
  height: 100%;
  font-size: 16px;
  font-weight: normal;
  transform: matrix(1, 0, 0, 1, 0, 0);
}

@supports (backdrop-filter: none) {
  .overlay-background {
    background-image: none;
    background-color: rgba($color-palette-black, 0.59);
    backdrop-filter: blur(27px) brightness(131%);
  }
}

.overlay-content {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px;
}

.overlay-center {
  display: flex;
  justify-content: center;
  align-items: center;

  .overlay-content > * {
    margin: 0 auto;
  }
}
</style>
