<template>
    <div :class="getWrapClass" class="custom-select-in-forms">
        <div v-if="hasError" class="error-text" v-html="getError"></div>
        <label v-if="!hasError && value" :for="name" v-html="formatTitle"></label>
        <div class="main-select select">
            <v-select
                :id="name"
                :key="value"
                class="custom-styles"
                :searchable="false"
                :value="getValue()"
                :label="nameKey"
                :placeholder="getPlaceholder"
                :options="options"
                :autocomplete="autocomplete"
                :reduce="option => option[valueKey]"
                :required="required"
                :clearable="false"
                :disabled="disabled"
                @input="updateValue">
            </v-select>
        </div>
    </div>
</template>

<script>
    import vSelect from 'vue-select'
    import { ElementMixin, SelectorMixin, ErrorsMixin } from './mixins';

    export default {
        components: {
            vSelect,
        },
        mixins: [ElementMixin, SelectorMixin, ErrorsMixin],
        props: {
            loadingOptions: {
                type: Boolean,
                default: false,
            },
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
            getValue() {
                return this.value !== undefined && this.value !== null ?
                    this.options.find((option) => option[this.valueKey] === this.value)
                    : '';
            },
        }
    }
</script>

<style lang="scss">
    @import "vue-select/src/scss/vue-select.scss";
    
    .custom-select-in-forms {
        .vs__dropdown-menu li {
            padding: 10px 15px;
        }
        .vs__dropdown-toggle {
            height: 50px;
            padding: 0 !important;
            margin: 0 !important;
            border: none;
        }
        .vs__selected-options {
            padding: 0 !important;
        }

        .vs__selected-options input {
            color: $color-palette-white;
            line-height: 50px;
            margin: 0;
            padding: 0 0 0 15px !important;
        }
        .vs__search, .vs__search:focus {
            color: $color-palette-white;
            line-height: 50px;
        }
        .vs--single .vs__selected {
            padding: 0 0 0 15px !important;
            margin: 0;
        }
        .vs--single.vs--open .vs__selected {
            top: 51%;
            transform: translateY(-50%);
            padding: 0 0 0 15px !important;
            margin: 0;
        }
    }

    /*.custom-styles .vs__search::placeholder,
    .custom-styles .vs__dropdown-toggle,
    .custom-styles .vs__dropdown-menu {
        background: #313639;
        border: none;
        color: #FFF;
        text-transform: lowercase;
        font-variant: small-caps;
    }

    .custom-styles .vs__selected {
        color: #FFF;
    }

    .custom-styles .vs__clear,
    .custom-styles .vs__open-indicator {
        fill: #FFF;
    }*/
</style>