<template>
    <div>
        <div v-if="hasError" class="error-text" v-html="getError"></div>
        <div v-for="(option, i) in options" :key="`${option}_${i}`" :class="getWrapClass">
            <input
                :id="`${name}_option_${i}`"
                type="radio"
                :name="name"
                :disabled="disabled"
                :value="option[valueKey]"
                :checked="option[valueKey].toString() === checkedValue.toString()"
                @input="updateValue" />
            <label :for="`${name}_option_${i}`">
                <span class="radio">
                    <i></i>
                </span>
                <span class="checkbox-text" v-text="option[nameKey]"></span>
            </label>
            <slot :name="`option_${i}`" :checked="option[valueKey].toString() === checkedValue.toString()"></slot>
        </div>
    </div>
</template>

<script>
    import { ElementMixin, ErrorsMixin } from './mixins';

    export default {
        mixins: [ElementMixin, ErrorsMixin],
        props: {
            wrapClass: {
                type: String,
                default: 'custom-checkbox-input-container',
            },
            options: {
                type: Array,
                required: true,
            },
            valueKey: {
                type: String,
                default: 'id',
            },
            nameKey: {
                type: String,
                default: 'name',
            },
            defaultValue: {
                type: String,
                default: '-1',
            }
        },
        data: function() {
            return {
                checkedValue: '',
            };
        },
        computed: {
            getValue() {
                return this.value !== undefined && this.value !== null ? this.value.toString() : this.defaultValue;
            },
        },
        mounted() {
            this.checkedValue = this.getValue;
            this.$emit('input', this.getValue);
        },
        methods: {
            updateValue(event) {
                this.checkedValue = event.target.value;
                this.$emit('input', event.target.value);
            },
        }
    }
</script>
