<template>
    <nav class="secondary-nav">
        <ul>
            <li>
                <router-link :to="{ name: 'home' }" @click.native="gtm_Home">
                    {{ $t('navigation.home') }}
                </router-link>
            </li>
            <li>
                <router-link :to="{ name: 'events' }" @click.native="gtm_Events">
                    {{ $t('navigation.events') }}
                </router-link>
            </li>
            <li>
                <router-link
                    :to="{ name: 'notifications' }"
                    @click.native="gtm_Notifications"
                >
                    {{ $t('navigation.messages') }}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
  methods: {
    gtm_Home() {
      this.$gtm.trackEvent({
        category: 'Navigation',
        action: 'top',
        label: '/home',
      });
    },
    gtm_Events() {
      this.$gtm.trackEvent({
        category: 'Navigation',
        action: 'top',
        label: '/events',
      });
    },
    gtm_Notifications() {
      this.$gtm.trackEvent({
        category: 'Navigation',
        action: 'top',
        label: '/notifications',
      });
    },
  },
};
</script>

<style lang="scss">
.secondary-nav {
  display: none;

  @include breakpoint('desktop') {
    display: block;
    background-color: $color-palette-white;
    height: 60px;

    ul {
      li {
        position: relative;
        display: inline-block;
        margin-left: 40px;

        &:hover {
          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 3px;
            background-color: $color-palette-purple-1;
          }
        }

        &:first-child {
          margin-left: 25px;
        }

        a {
          color: $color-palette-black;
          font-size: 16px;
          line-height: 60px;
          text-decoration: none;

          &:hover {
            color: $color-palette-purple-1;
          }

          &.router-link-active {
            &:after {
              content: '';
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 3px;
              background-color: $color-palette-purple-1;
            }
          }
        }
      }
    }
  }
}
</style>
