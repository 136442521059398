import api from '@/api';

const state = {
  supportCases: {
    isLoading: false,
    data: [],
  },
};

const getters = {
  supportCases: state => state.supportCases.data,
  hasSupportCases: state => state.supportCases.data.length > 0,
  isLoadingSupportCases: state => state.supportCases.isLoading,
};

const mutations = {
  setSupportCases(state, supportCases) {
    state.supportCases.data = supportCases;
  },
  setLoadingSupportCases(state, isLoading) {
    state.supportCases.isLoading = isLoading;
  },
};

const actions = {
  async loadSupportCases({ getters, commit }) {
    // try loading from localStorage
    commit('setSupportCases', JSON.parse(localStorage.getItem('supportCasesStorage')) || []);

    // load from API
    if (!getters.hasSupportCases && !getters.isLoadingSupportCases) {
      commit('setLoadingSupportCases', true);
      let {
        data: { data: supportCases },
      } = await api.get('support/cases');
      commit('setSupportCases', supportCases);
      await localStorage.setItem('supportCasesStorage', JSON.stringify(supportCases));
      localStorage.removeItem('supportCases');
      commit('setLoadingSupportCases', false);
    }
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
