<template>
    <div class="password-input-container">
        <span
            class="eye-icon"
            :class="{'not-visible': visiblePassword}"
            @click.prevent="togglePasswordVisibility">
        </span>
        <text-input
            :name="name"
            :lang-section="langSection"
            :type="visiblePassword ? 'text' : 'password'"
            :value="value"
            :errors="errors"
            :autocomplete="autocomplete"
            :required="required"
            @input="updateValue">
        </text-input>
    </div>
</template>

<script>
    import { TextInput } from '@/components/form';
    import { ElementMixin, ErrorsMixin } from '@/components/form/mixins';

    export default {
        components: {
            TextInput,
        },
        mixins: [ElementMixin, ErrorsMixin],
        props: {
            type: {
                type: String,
                default: 'password',
            },
        },
        data: function () {
            return {
                visiblePassword: false,
            };
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
            togglePasswordVisibility() {
                this.visiblePassword = !this.visiblePassword;
            },
        },
    }
</script>
<style lang="scss">
    .password-input-container {
        position: relative;

        .eye-icon {
            display: block;
            position: absolute;
            bottom: 12px;
            right: 15px;
            cursor: pointer;
            z-index: 1;

            &.not-visible:before {
                content: "\E638";
            }

            &:before {
                content: "\E639";
                color: $color-palette-grey-5;
                display: block;
                font-family: $font-icon;
                font-size: 1.5rem;
            }

            &:hover {
                &:before {
                    color: $color-palette-purple-1;
                }
            }
        }
    }
</style>
