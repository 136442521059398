import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['currentUser', 'markets']),
    currentUserMarket() {
      if(this.currentUser)
          return this.currentUser.market_id
          ? this.markets.find(market => market.id === this.currentUser.market_id)
          : {};
      else
      {
        fetch("https://ipinfo.io/json?token=ipinfo.io").then(
          (response) => response.json()
        ).then(
          (jsonResponse) => {
            if(["US", "CA"].includes(jsonResponse.country))
            {
              this.copyrightData.default = this.copyrightData.market.PCNA;
              this.showPCNA = true;
            }
          }
        )        
      }
    },
  },
};
