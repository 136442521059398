import api from '@/api';

const state = {
  currentUser: null,
  countUnreadNotifications: 0,
  userBookings: [],
  loginInProgress: false,
  loginError: null,
  isUCLoaded: false,
  isSessionExpired: false,
  isChangingPassword: false,
  isSubscribingNewsletter: false,
  isViewingOnboarding: false,
  isAcceptingTerms: false,
  acceptingTermsCallback: null,
};

const getters = {
  isAuthenticated: state => !!state.currentUser,
  currentUser: state => state.currentUser,
  countUnreadNotifications: state => state.countUnreadNotifications,
  userBookings: state => state.userBookings,
  loginInProgress: state => state.loginInProgress,
  loginError: state => state.loginError,
  hasLoginError: state => state.loginError !== null,
  getUCLoaded: state => state.isUCLoaded,
  isSessionExpired: state => state.isSessionExpired,
  isChangingPassword: state => state.isChangingPassword,
  isSubscribingNewsletter: state => state.isSubscribingNewsletter,
  isViewingOnboarding: state => state.isViewingOnboarding,
  isAcceptingTerms: state => state.isAcceptingTerms,
  acceptingTermsCallback: state =>
    typeof state.acceptingTermsCallback === 'function' ? state.acceptingTermsCallback : null,
};

const mutations = {
  setUser(state, user) {
    state.currentUser = user;
  },
  setUserProp(state, prop) {
    state.currentUser[prop.name] = prop.value;
  },
  removeUser(state) {
    state.currentUser = null;
    localStorage.setItem('greetingShown', '0');
    api.endSession();
  },
  setLoginInProgress(state, isInProgress) {
    state.loginInProgress = isInProgress;
  },
  setLoginError(state, error) {
    state.loginError = error;
  },
  clearLoginError(state) {
    if (state.loginError !== null) {
      state.loginError = null;
    }
  },
  setUserBookings(state, bookings) {
    state.userBookings = bookings;
  },
  addUserBooking(state, booking) {
    state.userBookings.push(booking);
  },
  setCountUnreadNotifications(state, countUnreadNotifications) {
    state.countUnreadNotifications = countUnreadNotifications;
  },
  setUCLoaded(state, isLoaded) {
    state.isUCLoaded = isLoaded;
  },
  setSessionExpired(state, isSessionExpired) {
    state.isSessionExpired = isSessionExpired;
  },
  setChangingPassword(state, isChangingPassword) {
    state.isChangingPassword = isChangingPassword;
  },
  setSubscribingNewsletter(state, isSubscribingNewsletter) {
    state.isSubscribingNewsletter = isSubscribingNewsletter;
  },
  setViewingOnboarding(state, isViewingOnboarding) {
    state.isViewingOnboarding = isViewingOnboarding;
  },
  setAcceptingTerms(state, { isAcceptingTerms, callback = null }) {
    state.isAcceptingTerms = isAcceptingTerms;
    state.acceptingTermsCallback = callback;
  },
};

const actions = {
  async doLogin({ commit }, user, isAdmin) {
    if (isAdmin === undefined) {
      isAdmin = false;
    }
    const loginPath = isAdmin ? 'auth/login/admin' : 'auth/login';
    commit('setLoginInProgress', true);
    try {
      await api.post(loginPath, user, { cache: false });
      api.startSession();
    } catch (e) {
      commit('setLoginError', e.response.data.error);
    }
    commit('setLoginInProgress', false);
  },
  async doLogout({ commit }) {
    await api.post('auth/logout', {}, { cache: false });
    commit('removeUser');
    commit('setPages', []);
  },
  async getUser({ commit }) {
    let { data } = await api.get('user/current?with-bookings', { cache: false });
    commit('setUser', data.user);
    commit('setUserBookings', data.bookings);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
