import Vue from 'vue';
import Router from 'vue-router';
import gtm from '@/lib/gtm';
import api from '@/api';
import trimStart from 'lodash/trimStart';

Vue.use(Router);

// Layouts
import AuthLayout from '@/layouts/AuthLayout';
import GuestLayout from '@/layouts/GuestLayout';
import isEqual from 'lodash/isEqual';

Vue.component('AuthLayout', AuthLayout);
Vue.component('GuestLayout', GuestLayout);

const ifNotAuthenticated = (to, from, next) => {
    if (api.hasSession()) {
        next({name: 'home'});
        return;
    }

    next();
};

const ifAuthenticated = (to, from, next) => {
    if (api.hasSession()) {
        next();
        return;
    }

    if (to.path.indexOf('logout') === -1) {
        next({name: 'login', params: {link: to.path}});
    } else {
        next('/login');
    }
};

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // {
        //     path: '/customvideo',
        //     name: 'CustomVideo',
        //     component: () => import('./components/common/CustomVideo'),
        //     meta: {layout: "auth"},
        //     beforeEnter: ifAuthenticated,
        // },
        {path: '/', redirect: '/home'},
        {
            path: '/transfer',
            name: 'handle-transfer',
            component: () => import('./views/Transfer'),
        },
        {
            path: '/403',
            name: '403',
            component: () => import('./views/PermissionDenied'),
            meta: {layout: 'guest'},
        },
        {
            path: '/404',
            name: '404',
            component: () => import('./views/PageNotFound'),
            meta: {layout: 'guest'},
        },
        {
            path: '/support',
            name: 'support',
            component: () => import('./views/auth/Support'),
            meta: {layout: 'guest'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/welcome',
            name: 'welcome',
            component: () => import('./views/guest/Welcome'),
            meta: {layout: 'dynamic', section: 'app'},
        },
        {
            path: '/login/:link?',
            name: 'login',
            component: () => import('./views/guest/Login/Login'),
            meta: {layout: 'guest', section: 'app'},
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/find-account',
            name: 'find-account',
            component: () => import('./views/guest/AccountFinder'),
            meta: {layout: 'guest', section: 'app'},
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('./views/guest/Register'),
            meta: {layout: 'guest', section: 'app'},
            props: true,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/activate/:code',
            name: 'activation',
            component: () => import('./views/guest/Activation'),
            meta: {layout: 'guest', section: 'app'},
            props: true,
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/password/forgot',
            name: 'password-forgot',
            component: () => import('./views/guest/ForgottenPassword'),
            meta: {layout: 'guest', section: 'app'},
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/password/reset',
            name: 'password-reset',
            component: () => import('./views/guest/ResetPassword'),
            meta: {layout: 'guest', section: 'app'},
            beforeEnter: ifNotAuthenticated,
        },
        {
            path: '/logout',
            name: 'logout',
            component: () => import('./views/auth/Logout'),
            meta: {layout: 'guest'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('./views/auth/Home/Home'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/story/:slug',
            name: 'story',
            component: () => import('./views/auth/Story/Story'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/podcasts/',
            name: 'podcasts',
            component: () => import('./views/auth/PodcastSection/Podcasts'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/podcasts/:slug',
            name: 'podcast',
            component: () => import('./views/auth/PodcastSection/PodcastDetail'),
            meta: {layout: 'auth', section: 'app'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events',
            name: 'events',
            component: () => import('./views/auth/Events/Events'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug',
            name: 'event',
            component: () => import('./views/auth/EventDetails/EventDetails'),
            meta: {layout: 'auth', section: 'app'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug/gallery',
            name: 'event-gallery',
            component: () => import('./views/auth/EventGallery'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug/stream',
            name: 'event-stream',
            component: () => import('./views/auth/EventDetails/EventStream'),
            meta: {layout: 'auth'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug/booking-portal',
            name: 'booking-portal',
            component: () => import('./views/auth/EventRegistration/BookingPortal'),
            meta: {layout: 'auth', section: 'app'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug/register',
            name: 'event-registration',
            component: () => import('./views/auth/EventRegistration/EventRegistration'),
            meta: {layout: 'auth', section: 'booking'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/user/profile',
            name: 'profile',
            component: () => import('./views/auth/Profile/Profile'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/page/:section/:slug',
            name: 'page',
            component: () => import('./views/Page'),
            props: true,
            meta: {layout: 'dynamic'},
        },
        {
            path: '/notifications',
            name: 'notifications',
            component: () => import('./views/auth/Notifications/Notifications'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('./views/auth/Settings'),
            meta: {layout: 'auth', section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug/last-details',
            name: 'last-details',
            component: () => import('./views/auth/LastDetails/LastDetails'),
            meta: {layout: 'auth', section: 'booking'},
            props: true,
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/welcome-present',
            name: 'welcome-present',
            component: () => import('./views/auth/WelcomePresent'),
            meta: {layout: 'auth', section: 'app'},
            beforeEnter: ifAuthenticated,
        },
        {
            path: '/events/:slug/waiting-list',
            name: 'waiting-list',
            component: () => import('./views/auth/WaitingList'),
            meta: {layout: 'auth', section: 'app'},
            beforeEnter: ifAuthenticated,
            props: true,
        },
        {
            path: '/events/:slug/prebooking',
            name: 'prebooking',
            component: () => import('./views/auth/Prebooking'),
            meta: {layout: 'auth', section: 'app'},
            beforeEnter: ifAuthenticated,
            props: true,
        },
        {
            path: '*',
            component: () => import('./views/PageNotFound'),
        },
        {
            path: '/moments',
            name: 'moments',
            component: () => import('./views/auth/Moments/Moments'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
            children: [
                {
                    path: 'share',
                    name: 'moment-create',
                    component: () => import('./views/auth/Moments/MomentCreateModal'),
                    meta: {layout: 'auth', section: 'app'},
                },
                {
                    path: ':uid',
                    name: 'moment-detail',
                    component: () => import('./views/auth/Moments/MomentDetailOverlay'),
                    meta: {layout: 'auth', section: 'app'},
                    props: true,
                },
            ],
        },
        {
            path: '/members/:id',
            name: 'member-profile',
            component: () => import('./views/auth/MemberProfile'),
            meta: {layout: 'auth', hasMainNav: true, section: 'app'},
            beforeEnter: ifAuthenticated,
            props: true,
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        const savePositionFromTo = [
            {from: 'moments', to: 'moment-detail'},
            {from: 'moments', to: 'moment-create'},
            {from: 'moment-detail', to: 'moments'},
            {from: 'moment-create', to: 'moments'},
        ];

        switch (true) {
            case savedPosition:
                return savedPosition;

            case from.name === to.name && isEqual(to.params, from.params):
            case savePositionFromTo.some(fromTo => fromTo.from === from.name && fromTo.to === to.name):
                return false;

            default:
                return {x: 0, y: 0};
        }
    },
});

router.beforeEach((to, from, next) => {
    const appURL = process.env.VUE_APP_APP;
    const bookingURL = process.env.VUE_APP_BOOKING;
    const toSection = to.meta.section || undefined;
    let currentSection = undefined;

    if (window.location.href.indexOf(appURL) !== -1) {
        currentSection = 'app';
    } else if (window.location.href.indexOf(bookingURL) !== -1) {
        currentSection = 'booking';
    }
    if (currentSection && toSection && toSection !== currentSection) {
        var lang;
        try {
            lang = JSON.parse(localStorage.getItem('currentLanguage')) || {code: 'en_EN'};
        } catch (e) {
            lang = {code: 'en_EN'};
        }

        if (toSection === 'booking') {
            window.location.replace(`${bookingURL}transfer?path=${to.fullPath}&lang=${lang.code}`);
            return;
        } else if (toSection === 'app') {
            window.location.replace(`${appURL}transfer?path=${to.fullPath}&lang=${lang.code}`);
            return;
        }
    }

    next();
});

router.afterEach(to => {
    let name = trimStart(to.fullPath, '/');
    setTimeout(function () {
        gtm.trackView(name);
    }, 300);
});

export default router;
