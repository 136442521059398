<template>
    <div>
        <p v-html="$t('footer_mvc.footer_p0', { year: new Date().getFullYear() })"></p>
        <p>
            <router-link
                v-for="page in bookingPages"
                :key="`page-${page.id}`"
                :to="{ name: 'page', params: { section: page.section, slug: page.slug, pageData: page, } }"
            >
                {{ page.translations.title }}
            </router-link>
        </p>
        <p v-html="$t('footer_mvc.footer_p1', { email: contactEmail, phone: contactPhone })"></p>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        computed: {
            ...mapGetters([
                'bookingPages',
            ]),
            contactPhone() {
                return `<a href="tel:+4915255868911">+49 (0)1525 58-68-911</a>`;
            },
            contactEmail() {
                return `<a href="mailto:ThePorscheCircle@metzler-vater.com">ThePorscheCircle@metzler-vater.com</a>`;
            },
        },
    }
</script>
